<template>
  <div class="login-page">
    <div class="login-box">
      <div class="title">
        <span>飞鱼商户管理系统</span>
      </div>
      <div class="content" v-loading="loading">
        <el-form :model="loginData" status-icon :rules="rules" ref="loginForm" @submit.native.prevent>
          <el-form-item prop="phone">
            <el-input v-model="loginData.phone" maxlength="11" @input="phoneChange">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="merchantsId" v-if="merchantList.length > 0">
            <el-select prefix="el-icon-school" @change="merchantsChange" v-model="loginData.merchantsId" style="width: 360px" placeholder="请选择">
                <el-option
                    v-for="item in merchantList"
                    :key="item.merchants_id"
                    :label="item.merchants_name"
                    :value="item.merchants_id">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              v-model="loginData.password"
              autocomplete="off"
            >
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              native-type="submit"
              type="primary"
              @click="submitLogin('loginForm')"
              style="width: 100%"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Login',
    data () {
        // 验证密码是否正确
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'))
            } else {
                if (this.loginData.checkPass !== '') {
                    this.$refs.loginForm.validateField('checkPass')
                }
                callback()
            }
        }

        return {
            // 验证规则
            rules: {
                phone: [
                    { required: true, message: '请输入电话号码', trigger: 'change' },
                    {
                        pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                        message: '请输入正确的手机号码'
                    }
                ],
                password: [{ validator: validatePass, trigger: 'change' }]
            },

            // 登录数据
            loginData: {
                phone: '',
                merchantsId: '',
                password: ''
            },
            loading: false,

            merchantList: [],
            merchantInfo: {},
        }
    },
    mounted () {
        console.log('Login')
    },
    methods: {
        phoneChange (value) {
            const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
            if (reg.test(value)) {
                this.loading = true
                console.log(value)
                this.axios.post('/get/merchants/list', {
                    phone: value
                }).then((res) => {
                    console.log(res)
                    setTimeout(() => {
                        this.loading = false
                    }, 200)
                    if (res.data.list.length > 0) {
                        this.merchantList = res.data.list
                        this.loginData.merchantsId = this.merchantList[0].merchants_id
                        this.merchantInfo = this.merchantList[0]
                    } else {
                        this.$message.error('该电话未注册未商户管理员，请联系系统管理员处理！')
                        this.merchantList = []
                        this.loginData.merchantsId = ''
                    }
                }).catch(() => {
                })
            }
        },
        merchantsChange () {
            console.log(this.loginData.merchantsId)
            this.merchantInfo = this.merchantList.filter(item => {
                return item.merchants_id === this.loginData.merchantsId
            })[0]
        },
        submitLogin (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.axios.post('/login', {
                        phone: this.loginData.phone,
                        password: this.loginData.password,
                        merchants_id: this.loginData.merchantsId
                    }).then((res) => {
                        console.log(res)
                        if (res) {
                            localStorage.setItem('merchantInfo', JSON.stringify(this.merchantInfo))
                            this.$router.push('/home')
                        }
                    }).catch(() => {
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
.login-page {
  height: 100%;
  display: flex;
  align-items: center;
  background: #f0f2f5;
  justify-content: center;

  .login-box {
    // display: flex;
    text-align: center;
    width: 360px;
    height: 360px;
    background-color: #ffffff;
    padding: 40px 30px 20px;

    .title {
      width: 100%;
      text-align: center;
      line-height: 60px;
      font-size: 28px;
      color: #0d2f54;
      margin-bottom: 20px;
    }

    .content {
      .el-form-item {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
