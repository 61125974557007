import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import Login from "../views/login/login.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/home",
        component: () => import("../views/Home.vue"),
        children: [
            {
                path: "",
                component: () => import("../views/index/index.vue"),
            },
            {
                path: "index",
                component: () => import("../views/index/index.vue"),
            },
            {
                path: "order",
                component: () => import("../views/order/order.vue"),
            },
            {
                path: "rider",
                component: () => import("../views/rider/rider.vue"),
            },
            {
                path: "goods",
                component: () => import("../views/goods/goods.vue"),
            },
            {
                path: "goods/add",
                component: () => import("../views/goods/addGoods.vue"),
            },
            {
                path: "goods/group",
                component: () => import("../views/goods/goodsGroup.vue"),
            },
            {
                path: "printer",
                component: () => import("../views/printer/printer.vue"),
            },
            {
                path: "configure",
                component: () => import("../views/configure/configure.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
